<template>
    <app-wrapper>
        <template v-slot:child>
            <CreateCategory />
            <CreateBrand />

            <Spinner v-if="!product" />

            <div v-else class="w-100 center pt0-l mini-spacing">
                <div class="w-90 pv2 center box-border" style="background: white">
                    <div class="flex justify-between pa3">
                        <div></div>
                        <div></div>
                    </div>
                    
                    <form class="entity-form" ref="formRef" @submit.prevent="onSubmit">
                        <div class="box-border-bottom f3 pa3 b">Product</div>
                        <div class="pa3">
                            <!-- SKU /Barcode -->
                            <div class="flex flex-wrap justify-between pt2">
                                <div class="w-50-l w-100 pr2-l">
                                    <div class="pv2 font-w1">
                                        <label for="sku">SKU<span class="required">*</span></label>
                                    </div>
                                    <input required type="text" id="sku" name="sku" placeholder="sku" v-model="state.sku" />
                                </div>
                                <div class="w-50-l w-100 pl2-l">
                                    <div class="pv2 font-w1">
                                        <label for="barcode"> Barcode </label>
                                    </div>
                                    <input
                                        type="text"
                                        id="barcode"
                                        name="barcode"
                                        placeholder="0123ABC"
                                        v-model="state.barcode"
                                    />
                                </div>
                            </div>

                            <div class="flex flex-wrap justify-between">
                                <!-- Product name -->
                                <div :class="!state.isService ? 'pt2 w-100 w-50-l' : 'pt2'">
                                    <div class="pv2 font-w1"><label for="name">Product Name</label></div>
                                    <input
                                        required
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Product name"
                                        v-model="state.name"
                                    />
                                </div>

                                <!-- Measurement unit -->
                                <div v-if="!state.isService" class="pt2 w-100 w-50-l pl2-l">
                                    <div class="pv2 font-w1"><label for="name">Measurement</label></div>
                                    <select v-model="state.measurement" class="w-100"  v-tippy="{ content: 'Select the unit of measurement of the product you are inputting e.g carton for products that are in cartons', placement: 'top', interactive: true }">
                                        <option value="Unit">Unit</option>
                                        <option value="Piece">Piece</option>
                                        <option value="Kg">Kg</option>
                                        <option value="Litre">Litre</option>
                                        <option value="Dozen">Dozen</option>
                                        <option value="Carton">Carton</option>
                                        <option value="Pack">Pack</option>
                                        <option value="Portion">Portion</option>
                                        <option value="Wrap">Wrap</option>
                                        <option value="Plate">Plate</option>
                                        <option value="Bottle">Bottle</option>
                                        <option value="Cup">Cup</option>
                                        <option value="Yard">Yard</option>
                                        <option value="Pairs">Pairs</option>
                                        <option value="Hour">Hour</option>
                                        <option value="Day">Day</option>
                                        <option value="Week">Week</option>
                                        <option value="Month">Month</option>
                                        <option value="Grammes">Grammes</option>
                                    </select>
                                </div>
                            </div>

                            <!--  Image area-->
                            <div
                                class="flex flex-wrap justify-between items-center pt3"
                                @drop.prevent="handleDragImage"
                                @dragover.prevent
                                @dragenter.prevent
                            >
                                <div class="w-20-l w-90 pb3 b f4 pb0-l box-border1 br-100 overflow-hidden">
                                    <img
                                        :src="state?.image || state?.imageUrl || require('@/assets/images/inventory-default.png')"
                                        alt="Org Image"
                                        class="h-90 w-90 pointer"
                                        style="object-position: center; object-fit: cover"
                                        @click.prevent="handleClickUpload"
                                    />
                                </div>
                                <div class="box-border pa4 w-80-l w-90">
                                    <span class="dn">
                                        <input
                                            ref="imageElRef"
                                            type="file"
                                            name="imageFile"
                                            id="imageFile"
                                            accept="image/*"
                                            @change.prevent="handleSelectImage"
                                        />
                                    </span>
                                    <span
                                        class="font-w1 lh-copy pointer"
                                        style="color: #132c8c"
                                        @click.prevent="handleClickUpload"
                                        >Upload
                                    </span>
                                    or drag and drop your company logo PNG, JPG, GIF up to 10MB
                                </div>
                            </div>

                            <!-- textarea -->
                            <div class="pt2">
                                <div class="pv2 font-w1">
                                    <label>Description</label>
                                </div>

                                <textarea
                                    placeholder="Type here"
                                    name="description"
                                    id="description"
                                    cols="20"
                                    rows="5"
                                    v-model="state.description"
                                ></textarea>
                            </div>
                            <!-- categories/brand -->
                            <div class="flex pt2">
                                <div class="w-50 pr2">
                                    <div class="flex items-center" style="gap: 0.5rem">
                                        <div class="pv2">
                                            <label>Category</label>
                                        </div>
                                        <router-link @click.prevent="onShowCreateCategory(true)" to="#" class="pv2 active bold"
                                            >+ Add New Category</router-link
                                        >
                                    </div>

                                    <select name="categories" class="w-100" id="category" v-model="state.category">
                                        <option value="" selected>Please choose a category</option>
                                        <template v-for="category of categories.data" v-bind:key="category._id">
                                            <option :value="category._id">{{ category.name }}</option></template
                                        >
                                    </select>
                                </div>
                                <div class="w-50 pl2">
                                    <div class="flex items-center" style="gap: 0.5rem">
                                        <div class="pv2"><label for="brands">Brand</label></div>
                                        <router-link @click.prevent="onShowCreateBrand(true)" to="#" class="pv2 active bold">
                                            + Add New Brand</router-link
                                        >
                                    </div>

                                    <select name="brands" class="w-100" id="brands" v-model="state.brand">
                                        <option value="" selected>Please choose a brand</option>
                                        <template v-for="brand of brands.data" v-bind:key="brand._id">
                                            <option :value="brand._id">{{ brand.name }}</option></template
                                        >
                                    </select>
                                </div>
                            </div>
                            <!-- size/color -->
                            <div v-if="!state.isService" class="flex pt2">
                                <div class="w-50 pr2">
                                    <div class="pv2"><label for="size">Size</label></div>
                                    <input type="text" placeholder="Product Size" id="size" name="size" v-model="state.size" />
                                </div>
                                <div class="w-50 pl2">
                                    <div class="pv2"><label for="color">Color</label></div>
                                    <input
                                        type="text"
                                        placeholder="Product Color"
                                        id="color"
                                        name="color"
                                        v-model="state.color"
                                    />
                                </div>
                            </div>

                            <div v-if="state.hasMultiBusiness" class="mt3">
                                <div class="b mb2">
                                    <label for="create-for-other-biz">
                                        <input id="create-for-other-biz" type="checkbox" v-model="state.createForOtherBiz" /> Create product in other businesses
                                    </label>
                                </div>
                                <table v-if="state.createForOtherBiz">
                                    <thead>
                                        <tr>
                                            <th>Check</th>
                                            <th>Business</th>
                                            <th>Qty in stock</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="business in state.businesses" :key="business._id">
                                            <td><input type="checkbox" v-model="business.isSelected" /></td>
                                            <td class="mr3">{{ business.name }}</td>
                                            <td><input type="number" v-model="business.qty_in_stock" :disabled="!business.isSelected" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Pricing -->
                            <div v-if="!state.isService" class="flex pt2">
                                <div class="w-50 pr2">
                                    <div class="pv2"><label for="size">Default selling price</label></div>
                                    <input
                                    :disabled = "role !== 'owner' && role !== 'admin' 
                                        && !rolePermissions?.includes(37)"
                                        type="number"
                                        step="any"
                                        placeholder="e.g 10000"
                                        id="sellingPrice"
                                        name="sellingPrice"
                                        v-model="state.sellingPrice"
                                    />
                                </div>
                                <div class="w-50 pl2">
                                    <div class="pv2"><label for="color">Default buying price</label></div>
                                    <input
                                    :disabled = "role !== 'owner' && role !== 'admin' 
                                        && !rolePermissions?.includes(37)"
                                        type="number"
                                        step="any"
                                        placeholder="e.g 10000"
                                        id="buyingPrice"
                                        name="buyingPrice"
                                        v-model="state.buyingPrice"
                                    />
                                </div>
                                <div class="w-100 w-100-l pl2">
                                    <div class="pv2 font-w1"><label for="name">Stock Level</label></div>
                                    <input
                                        required
                                        type="text"
                                        id="stockLevel"
                                        name="stockLevel"
                                        placeholder="Stock level"
                                        v-model="state.stockLevel"
                                    />
                                </div>
                            </div>

                            <div v-if="!state.isService" class="flex flex-wrap justify-between">
                                <!-- Reminder -->
                                <div class="w-100 w-50-l pr2-l">
                                    <div class="pv2">
                                        <label for="expiryDate">Expiry Date</label>
                                    </div>
                                    <div  v-tippy="{ content: 'The expiry date of the product if applicable', placement: 'top', interactive: true }">
                                    <input
                                        type="date"
                                        v-model="state.expiryDate"
                                        step="any"
                                        placeholder="e.g 10000"
                                        id="expiryDate"
                                        name="expiryDate"
                                    /></div>
                                </div>
                                <div class="w-100 w-50-l pl2-l">
                                    <div class="pv2">
                                        <label for="expiryReminderDays">Remind me {{ state.expiryReminderDays ? state.expiryReminderDays : '…' }} days to expiry</label>
                                    </div>
                                    <div  v-tippy="{ content: 'The number of days to remind you before product expires', placement: 'top', interactive: true }">
                                    <input
                                        type="number"
                                        v-model="state.expiryReminderDays"
                                        step="any"
                                        min="1"
                                        max="100"
                                        placeholder="e.g 10"
                                        id="expiryReminderDays"
                                        name="expiryReminderDays"
                                    /></div>
                                </div>
                            </div>

                            <div v-else class="flex pt2">
                                <div class="w-50 pl2">
                                    <div class="pv2"><label for="color">Unit Price</label></div>
                                    <input
                                        type="number"
                                        step="any"
                                        placeholder="e.g 10000"
                                        id="unitPrice"
                                        name="unitPrice"
                                        v-model="state.unitPrice"
                                    />
                                </div>
                            </div>

                            <button class="mt3" type="submit" :disabled="state.disableSubmitButton">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </template></app-wrapper
    >
</template>

<script>
import { reactive, computed, onMounted, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import AppWrapper from '@/layout/AppWrapper'
import Spinner from '@/components/Spinner'
import CreateCategory from '@/components/CreateCategory'
import CreateBrand from '@/components/CreateBrand'

export default {
    name: 'UpdateProduct',
    components: { AppWrapper, CreateBrand, CreateCategory, Spinner },

    setup() {
        const store = useStore()
        const route = useRoute()
        const imageElRef = ref(null)
        const product = computed(() => store.state?.Inventory?.productToUpdate)
        const brands = computed(() => store?.state.Inventory.brands)
        const categories = computed(() => store?.state.Inventory.categories)
        const role = computed(() => store?.state?.Auth?.role)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const { id } = route.params

        const state = reactive({
            disableSubmitButton: false,
            name: '',
            sku: '',
            barcode: '',
            description: '',
            measurement: 'Unit',
            category: '',
            brand: '',
            size: '',
            color: '',
            sellingPrice: 0,
            buyingPrice: 0,
            stockLevel: 0,
            expiryDate: null,
            expiryReminderDays: null,
            image: '',
            imageUrl: '',
            imageFile: '',
            isService: false,
            unitPrice: 0,
            businesses: [],
            hasMultiBusiness: false,
            createForOtherBiz: true,
        })

        watch(
            () => product.value,
            (prevValue, currValue) => {
                if (prevValue !== currValue) {
                    state.disableSubmitButton = false
                    Object.keys(state).forEach(keyName => {
                        state[keyName] = product?.value?.[keyName]
                    })

                    state.isService = product?.value?.type === 'SERVICE'
                    state.stockLevel = product?.value?.stockLevel || 0
                }
            }
        )

        const onShowCreateBrand = (payload = true) => {
            store.dispatch('Inventory/showCreateBrand', payload)
        }

        const onShowCreateCategory = (payload = true) => {
            store.dispatch('Inventory/showCreateCategory', payload)
        }

        const onSubmit = () => {
            state.disableSubmitButton = true
            if (!state.brand) delete state.brand
            if (!state.category) delete state.category

            const { disableSubmitButton, ...data } = state
            const formData = new FormData()

            const dataKeys = Object.keys(data)
            dataKeys.forEach(dataKey => {
                const excludeFields = ['variants', 'businesses', 'productType', 'hasMultiBusiness']
                if (excludeFields.includes(dataKey)) {
                    return
                }
                if (data[dataKey]) {
                    formData.append(dataKey, data[dataKey])
                }
            })

            let selectedBusinesses = state.businesses.filter(business => business.isSelected === true)
            selectedBusinesses = selectedBusinesses.map(business => {
                return { id: business.org, name: business.name, email: business.orgEmail, qty_in_stock: business.qty_in_stock }
            })
            
            if (selectedBusinesses.length) {
                formData.append('businesses', JSON.stringify(selectedBusinesses))
            }

            formData.append('type', product?.value?.type || "SERVICE")

            const payload = {}
            payload.id = id
            payload.formData = formData
            store.dispatch('Inventory/updateProduct', payload).then(result => {
                if (result) {
                    state.disableSubmitButton = false
                }
            })
        }

        const handleClickUpload = () => {
            // eslint-disable-next-line
            imageElRef?.value?.click()
            // imageElRef?.value.click('click')
        }

        const handleSelectImage = e => {
            if (e?.target?.files?.length) {
                const file = e.target.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.target.files[0]
                const reader = new FileReader()
                reader.onload = e => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const handleDragImage = e => {
            if (e?.dataTransfer?.files?.length) {
                const file = e.dataTransfer.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.dataTransfer.files[0]
                const reader = new FileReader()
                reader.onload = e => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        onMounted(() => {
            const { id } = route.params
            store.dispatch('Inventory/getProductToUpdate', id)
            store.dispatch('Inventory/getAllCategories')
            store.dispatch('Inventory/getAllBrands')
            store.dispatch('Settings/getBusinesses', true).then(resp => {
                if (resp.status) {
                    state.hasMultiBusiness = resp.data.length > 1
                    state.businesses = resp.data.map(business => {
                        business.isSelected = true
                        business.qty_in_stock = 0
                        return business
                    })
                }
            })
        })

        return {
            state,
            product,
            onSubmit,
            brands,
            categories,
            imageElRef,
            onShowCreateBrand,
            onShowCreateCategory,
            handleDragImage,
            handleSelectImage,
            handleClickUpload,
            role,
            rolePermissions
        }
    }
}
</script>

<style scoped></style>